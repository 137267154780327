import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Styles
import "../styles/pages/404.scss"

// Components
import SocialMedia from "../components/social-media"

const NotFoundPage = () => (
  <Layout pageName="page404" hasLightBackground>
    <SEO title="404: Not found" />
    <section className="section is-hero">
      <div className="container">
        <h1 className="has-text-centered title is-2 has-margin-bottom-6">
          Page not found
        </h1>
        <div className="columns is-centered">
          <div className="column is-two-fifths">
            <div className="is-card main-card">
              <h2 className=" has-text-white has-text-weight-bold has-text-centered text-404 has-margin-bottom-5">
                <span className="has-background-danger ">4</span>
                <span className="has-background-danger has-margin-right-3 has-margin-left-3">
                  0
                </span>
                <span className="has-background-danger">4</span>
              </h2>
              <p className="is-size-5 has-margin-bottom-3">
                Sorry, the page you were looking for at this URL was not found.
                <br /> <br />
                Find us @
              </p>
              <SocialMedia />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
